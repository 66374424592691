<template>
  <v-form
    ref="form"
    v-model="valid"
    class="rcc-return-points-form"
    @submit.prevent="onSubmitForm"
  >
    <div class="rcc-return-points-form__form-body">
      <rcc-text-input
        label="Название"
        width="30%"
        v-model="form.name"
        :rules="[value => isRequiredField({ value, field: 'Название' })]"
      />
      <rcc-text-input label="Адрес" width="30%" v-model="form.address" />
      <rcc-select
        label="Сеть"
        width="30%"
        :items="networksOptions"
        v-model="form.network_id"
        :rules="[value => isRequiredField({ value, errorText: 'Выберите сеть' })]"
      />
      <rcc-select
        label="Юр. лицо"
        width="30%"
        :items="legalEntitiesOptions"
        v-model="form.legal_entity_id"
        :rules="[value => isRequiredField({ value, errorText: 'Выберите юридическое лицо' })]"
      />

      <section class="rcc-return-points-form__form-section">
        <h2>Время подачи ТС</h2>

        <div class="rcc-return-points-form__time-wrapper">
          <rcc-time-input
            v-model="startTime"
            class="rcc-return-points-form__time-input"
            width="190px"
            label="от"
            :max="endTime"
            :rules="[startTimeValidation]"
          />

          <rcc-time-input
            v-model="endTime"
            class="rcc-return-points-form__time-input"
            width="190px"
            label="до"
            :min="startTime"
            :rules="[endTimeValidation]"
          />
        </div>
      </section>

      <rcc-select
        label="РЦ"
        width="30%"
        :items="distributionCentersOptions"
        v-model="form.dc_id"
        clearable
      />

      <rcc-text-input
        label="Контакты"
        width="30%"
        v-model="form.contacts"
        :rules=[contactsValidation]
      />

      <rcc-textarea v-model="form.note" label="Примечание" width="30%" />
    </div>

    <rcc-footer-buttons :is-submit="isSubmit" @cancel-click="$router.push({ name: 'return-points' })"/>
  </v-form>
</template>

<script>
import { getNetworksList } from '@/api/trading-networks'
import { getLegalEntities } from '@/api/legal-entities'
import { getOptions } from '@/utils/get-options'
import { getDCList } from '@/api/distribution-center'
import ReturnPointApi from '@/api/return-points'
import { timeDifference } from '@/utils/time'
import Page from '@/mixins/page'
import Form from '@/mixins/form'

import RccFooterButtons from 'Components/layouts/rcc-footer-buttons'
import RccTextInput from 'Components/controls/rcc-text-input'
import RccTimeInput from 'Components/controls/rcc-time-input'
import RccTextarea from 'Components/controls/rcc-textarea'
import RccSelect from 'Components/controls/rcc-select'

export default {
  components: {
    RccTextInput,
    RccFooterButtons,
    RccSelect,
    RccTimeInput,
    RccTextarea
  },

  mixins: [Page, Form, ReturnPointApi],

  data() {
    return {
      startTime: null,
      endTime: null,
      legalEntitiesOptions: [],
      networksOptions: [],
      distributionCentersOptions: [],

      form: {
        name: '',
        address: '',
        network_id: null,
        legal_entity_id: null,
        delivery: null,
        dc_id: null,
        contacts: '',
        note: ''
      }
    }
  },

  computed: {
    selects() {
      return [
        {
          method: getLegalEntities,
          target: 'legalEntitiesOptions',
          errorMessage: 'Не удалось загрузить данные для юр. лиц'
        },
        {
          method: getNetworksList,
          target: 'networksOptions',
          errorMessage: 'Не удалось загрузить данные для сетей'
        },
        { method: getDCList, target: 'distributionCentersOptions', errorMessage: 'Не удалось загрузить данные для РЦ' }
      ]
    },

    deliveryTime() {
      return [this.startTime, this.endTime]
    }
  },

  created() {
    getOptions.bind(this)(this.selects)
  },

  methods: {
    contactsValidation(value) {
      if (!value) {
        return 'Поле Контакты не может быть пустым'
      }
      return value.length <= 200 || 'Текст не может быть длиннее 200 символов'
    },

    startTimeValidation(value) {
      if(this.isDeliveryTimesEmpty) {
        return true
      }

      return !this.endTime ||
        (
          timeDifference(this.endTime, value, 'minutes') >= 0 ||
            'Не может быть больше ' + this.endTime
        )
    },

    endTimeValidation(value) {
      if(this.isDeliveryTimesEmpty) {
        return true
      }

      return !this.startTime ||
        (
          timeDifference(value, this.startTime, 'minutes') >= 0 ||
            'Не может быть меньше ' + this.startTime
        )
    },

    isDeliveryTimesEmpty() {
      return !this.startTime && !this.endTime
    },

    getOptions() {
      this.getLegalEntities().then(({ items }) => {
        this.legalEntities = items
      }),

      this.getDC().then(({ items }) => {
        this.distributionCenters = items
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-return-points-form {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__form-body {
    flex-grow: 1;
    padding: $base-form-padding;
  }

  &__form-section {
    margin: 10px 0;
  }

  &__time-wrapper {
    display: flex;
    gap: 20px;
  }

  &__time-input {
    flex: none
  }
}
</style>
